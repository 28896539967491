import { Box, Stack, Heading, Text } from '@chakra-ui/react';
import {useTranslation} from "next-i18next";

const CompanyInNumbersSection = () => {

  const {t} = useTranslation('landing');
  return (
    <Box as="section" bg="white" py={10} px={4} id="company-in-numbers">
      <Box
        maxW="1200px"
        mx="auto"
        p={6}
        border="2px"
        borderColor="orange.400"
        borderRadius="md"
        textAlign="center"
      >
        <Heading as="h2" size="lg" mb={6} color="blue.700">
          {t('landing:block4.numbers')}
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={10}
          justify="space-around"
          align="center"
        >
          <Box>
            <Text fontSize="4xl" color="blue.700" fontWeight="bold">
              465 000 €
            </Text>
            <Text fontSize="md" color="blue.500" fontWeight="bold">
              {t('landing:block4.revenue')}
            </Text>
          </Box>
          <Box>
            <Text fontSize="4xl" color="blue.700" fontWeight="bold">
              424
            </Text>
            <Text fontSize="md" color="blue.500" fontWeight="bold">
              {t('landing:block4.requests')}
            </Text>
          </Box>
          <Box>
            <Text fontSize="4xl" fontWeight="bold" color="blue.700">
              125
            </Text>
            <Text fontSize="md" color="blue.500" fontWeight="bold">
              {t('landing:block4.services')}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default CompanyInNumbersSection;
